/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Link, graphql, navigate } from 'gatsby'
import styled from '@emotion/styled'
import idx from 'idx'
import { PrismicRichText } from '@prismicio/react'

import LottieAnimation from '../LottieAnimation'
import Text from '../common/Text'
import Button from '../common/Button'
import { OpenNewTabHiddenSpan } from '../common/NewTabHiddenSpan'
import { trackEvent } from '../../utils/eventTrack'
import Space from '../common/Space'
import getImage from '../../utils/getImage'
import { theme } from '../../styles'
import { useUserLang } from '../../context/UserLangContext'
import greenCheckIcon from '../../../static/icons/check-icons/green-check.svg'

const BG = styled.div`
  position: absolute;
  width: 500vw;
  margin-left: -300%;
  height: 100%;
  z-index: -1;
  background-color: ${p => (p.backgroundColor ? theme.color[p.backgroundColor] : theme.color.white)};

  @media (max-width: 1050px) {
    margin-left: -100%;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: ${p => (p.hasBottomPadding ? '80px' : '0px')};
`

const ResourcesPanelWrapper = styled.div`
  background-color: ${p => (p.wrapperColor ? theme.color[p.wrapperColor] : theme.color.gray100)};
  background-color: ${p => p.noWrapper && 'transparent'};
  margin-top: ${p => (p.hasTopPadding ? '80px' : '0px')};
  padding: ${p => (p.noWrapper ? '0px' : '80px')};
  border-radius: 24px;

  @media (max-width: 1050px) {
    padding: 30px;
    margin: 32px 0px;
  }
  @media (max-width: 800px) {
    padding: 32px 16px;
  }
`

const ResourcesHeader = styled.div`
  margin-bottom: 24px;
  width: 100%;
  display: grid;
  ${p => !p.leftAlignTextContent && 'justify-content: center;'}

  @media (max-width: 1050px) {
    text-align: center;
  }
`

const Resources = styled.ul`
  ${props =>
    props.lang !== 'en' &&
    `
word-break: break-word;
hyphens: auto;
`}

  ${p =>
    p.twoCol
      ? `
    word-break: break-word;
    hyphens: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    `
      : `
    display: flex;
    justify-content: space-between;
    `}
  align-items: stretch;
  width: 100%;
  gap: 32px;
  padding: 16px 0px;
  flex-wrap: wrap;

  @media (max-width: 1050px) {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
  }
`

const ResourceWrapper = styled.li`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 0px;
  border-radius: 16px;
  background-color: ${p => (p.color ? theme.color[p.color] : theme.color.white)};
  transition: transform 0.2s ease-in-out;
  min-width: 300px;
  padding: ${p => (p.removePadding ? '0px' : p.largeCards ? '100px' : '32px')};
  gap: 8px;

  ${p =>
    p.hasLink &&
    `
    cursor: pointer;
  `}

  p {
    text-align: ${p => (p.leftAligned ? 'left' : 'center')};
    max-width: ${p => (p.leftAligned ? 'unset' : '660px')};
    margin: 0 auto !important;

    &[role='heading'] {
      max-width: ${p => (!p.largeCards && p.leftAligned ? 'unset' : '290px')};
    }
  }

  ${p =>
    p.hasLink &&
    `
    &:hover {
      box-shadow: 0px 2px 20px 0px ${theme.color.darkShadow};
      transform: scale(1.05);
    }
    `}

  @media (max-width: 1400px) {
    padding: ${p => (p.largeCards ? '64px' : '32px')};
  }

  @media (max-width: 1050px) {
    width: 100%;
  }

  @media (max-width: 800px) {
    min-width: unset;
    padding: ${p => (p.largeCards ? '40px' : '24px')};

    &:hover {
      transform: none;
      box-shadow: none;
    }
  }
`

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${p => (p.leftAligned ? 'flex-start' : 'center')};
  margin-bottom: 8px;

  img {
    margin: ${p => (p.leftAligned ? '0px 0px' : '0 auto')};
  }
`

const ResourceIcon = styled.img`
  max-height: 64px;
  display: block;
  object-fit: contain;
`

const ResourceLink = styled(Link)`
  text-align: right;
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .button.primary,
  .button.secondary {
    min-width: 200px;

    @media (max-width: 1050px) {
      margin: 0px 8px;
    }

    @media (max-width: 800px) {
      margin: 0 auto;
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;
    width: 100%;
  }
`

const WrapText = styled(Text)`
  ${props =>
    props.lang !== 'en' &&
    `
word-break: break-word;
hyphens: auto;
`}
`

const CheckmarkWrapper = styled.div`
  display: flex;
  gap: 24px;
  align-items: flex-start;

  img {
    margin-top: 4px;
  }
`

const Resource = ({ item, color, indx, largeCards }) => {
  const opensPdf = item.link && item.link.url?.includes('.pdf')
  const rawLottie = item.animation && item.animation.text
  const lottieAnim = rawLottie ? JSON.parse(item.animation.text) : null
  const text = item.text.richText?.[0]?.text && <PrismicRichText field={item.text.richText} />
  const checkmarkText = item.checkmark_text
  const removePadding = item.remove_padding
  const linkUrl = item?.link?.url
  const linkText = item?.link_text?.text
  const sameTab = !/http/.test(linkUrl) || /^audioeye.com$/.test(linkUrl) || linkUrl?.includes('audioeye.com')
  const leftAligned = item?.left_aligned
  const { userLang } = useUserLang()

  return linkUrl ? (
    <ResourceWrapper
      leftAligned={leftAligned}
      color={color}
      className="link-list-item external-link-wrapper focus-outline"
      hasLink
      largeCards={largeCards}
      removePadding={removePadding}
      onClick={() => {
        if (idx(item.link, _ => _.type)) {
          navigate(linkUrl)
        } else {
          if (typeof window !== 'undefined') window.open(linkUrl, '_blank')
          if (opensPdf) {
            trackEvent(`PDF Downloaded`, {
              'PDF Name': linkUrl,
            })
          }
        }
      }}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          if (idx(item.link, _ => _.type)) {
            navigate(linkUrl)
          } else if (e.key === 'Enter' && typeof window !== 'undefined') {
            window.open(linkUrl, '_blank')
          }
        }
      }}
    >
      {lottieAnim && typeof lottieAnim === 'object' && (
        <LottieAnimation lotti={lottieAnim} height={64} width={64} index={indx} />
      )}
      {item.icon?.url && (
        <ImageWrapper leftAligned={leftAligned}>{getImage(item.icon, ResourceIcon, { alt: '' })}</ImageWrapper>
      )}
      {(item.title1.text || text || checkmarkText) && (
        <div>
          {item.title1?.text && (
            <>
              <Text bodyBold role="heading" aria-level="3">
                {item.title1.text}
              </Text>
              <Space height={8} />
            </>
          )}
          {text && <Text richText>{text}</Text>}
          {checkmarkText && (
            <CheckmarkWrapper>
              <img src={greenCheckIcon} alt="" width="20" />
              <Text>{checkmarkText}</Text>
            </CheckmarkWrapper>
          )}
        </div>
      )}
      {item.link.type || sameTab ? (
        <ResourceLink to={item.link.url}>
          <Text smallBodyBold underline color="purple500">
            {linkText || 'Learn More'}
          </Text>
          {item.title1?.text && !linkText && <span className="sr-only">{`about ${item.title1.text}`}</span>}
        </ResourceLink>
      ) : (
        <>
          <a
            href={idx(item.link, _ => _.url)}
            onClick={e => {
              e.stopPropagation()
              if (opensPdf) {
                trackEvent(`PDF Downloaded`, {
                  'PDF Name': item.link.url,
                })
              }
            }}
            target="_blank"
            rel="noreferrer"
          >
            <Text smallBodyBold underline color="purple500">
              {linkText || 'Learn More'}
            </Text>
            {item.title1?.text && !linkText && <span className="sr-only">{`about ${item.title1.text}`}</span>}
          </a>
          <OpenNewTabHiddenSpan />
        </>
      )}
    </ResourceWrapper>
  ) : (
    <ResourceWrapper removePadding={removePadding} color={color} largeCards={largeCards} leftAligned={leftAligned}>
      {lottieAnim && typeof lottieAnim === 'object' && (
        <LottieAnimation lotti={lottieAnim} height={64} width={64} index={indx} />
      )}
      {item.icon?.url && (
        <ImageWrapper leftAligned={leftAligned}>{getImage(item.icon, ResourceIcon, { alt: '' })}</ImageWrapper>
      )}
      {(item.title1.text || text || checkmarkText) && (
        <div>
          {item.title1?.text && (
            <>
              <Text bodyBold role="heading" aria-level="3">
                {item.title1.text}
              </Text>
              <Space height={8} />
            </>
          )}
          {text && (
            <WrapText font="body" richText lang={userLang}>
              {text}
            </WrapText>
          )}
          {checkmarkText && (
            <CheckmarkWrapper>
              <img src={greenCheckIcon} alt="" width="20" />
              <Text>{checkmarkText}</Text>
            </CheckmarkWrapper>
          )}
        </div>
      )}
    </ResourceWrapper>
  )
}

const ResourcesPanel = ({ slice: { primary, items } }) => {
  const largeCapsText = primary.large_caps_text.richText?.[0]?.text && (
    <PrismicRichText field={primary.large_caps_text.richText} />
  )
  const title = primary.title1.richText?.[0]?.text && <PrismicRichText field={primary.title1.richText} />
  const text = primary.text.richText?.[0]?.text && <PrismicRichText field={primary.text.richText} />
  const anchorId = primary.anchor_id
  const wrapperColor = primary.wrapper_color
  const backgroundColor = primary.background_color
  const noWrapper = primary.no_wrapper
  const hasTopPadding = primary?.has_top_padding
  const hasBottomPadding = primary?.has_bottom_padding
  const twoCol = primary.two_column
  const largeCards = primary.large_cards
  const leftAlignTextContent = primary.left_align_text_content
  const primaryButtonText = primary?.primary_button_text.text
  const primaryButtonLink = primary?.primary_button_link.url || primary?.primary_button_link.uid
  const { userLang } = useUserLang()

  return (
    <Wrapper id={anchorId || null} hasBottomPadding={hasBottomPadding === null ? true : hasBottomPadding}>
      <ResourcesPanelWrapper
        wrapperColor={wrapperColor}
        noWrapper={noWrapper}
        hasTopPadding={hasTopPadding === null ? true : hasTopPadding}
      >
        {(title || text || largeCapsText) && (
          <ResourcesHeader leftAlignTextContent={leftAlignTextContent}>
            {largeCapsText && (
              <Text center={!leftAlignTextContent} font="largeCaps" richText color="purple500">
                {largeCapsText}
              </Text>
            )}
            {title && (
              <>
                <WrapText center={!leftAlignTextContent} font="heading2" richText>
                  {title}
                </WrapText>
                <Space height={25} />
              </>
            )}
            {text && (
              <Text
                center={!leftAlignTextContent}
                richText
                style={{ maxWidth: '900px', margin: `${leftAlignTextContent ? '0' : '0 auto'}` }}
              >
                {text}
              </Text>
            )}
          </ResourcesHeader>
        )}
        <Resources numItems={items.length} twoCol={twoCol} lang={userLang}>
          {items.map((item, indx) => (
            <Resource
              item={item}
              color={item.color}
              indx={indx}
              key={`resource-item-${indx}`}
              largeCards={largeCards}
            />
          ))}
        </Resources>
      </ResourcesPanelWrapper>
      {primaryButtonText && (
        <>
          <Space height={40} />
          <ButtonWrapper>
            {primaryButtonText && <Button text={primaryButtonText} to={primaryButtonLink} buttontype="tertiary" />}
          </ButtonWrapper>
        </>
      )}
      <BG className="bg" backgroundColor={backgroundColor} />
    </Wrapper>
  )
}

export default ResourcesPanel

export const query = graphql`
  fragment ResourcesPanel on PrismicPageDataBodyResourcesPanel {
    ...SliceBase
    items {
      color
      animation {
        ...TextContent
      }
      icon {
        alt
        url
        gatsbyImageData(width: 64)
      }
      link {
        ...LinkContent
      }
      text {
        ...TextContent
      }
      link_text {
        ...TextContent
      }
      title1 {
        ...TextContent
      }
      left_aligned
      checkmark_text
      remove_padding
    }
    primary {
      anchor_id
      text {
        ...TextContent
      }
      title1 {
        ...TextContent
      }
      wrapper_color
      background_color
      no_wrapper
      has_top_padding
      has_bottom_padding
      two_column
      large_cards
      left_align_text_content
      primary_button_text {
        ...TextContent
      }
      primary_button_link {
        ...LinkContent
      }
      large_caps_text {
        ...TextContent
      }
    }
  }
`
